exports.components = {
  "component---src-templates-business-jsx": () => import("./../../../src/templates/Business.jsx" /* webpackChunkName: "component---src-templates-business-jsx" */),
  "component---src-templates-city-jsx": () => import("./../../../src/templates/City.jsx" /* webpackChunkName: "component---src-templates-city-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-directv-jsx": () => import("./../../../src/templates/Directv.jsx" /* webpackChunkName: "component---src-templates-directv-jsx" */),
  "component---src-templates-dynamic-zip-packages-jsx": () => import("./../../../src/templates/DynamicZipPackages.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-jsx" */),
  "component---src-templates-dynamic-zip-packages-spanish-jsx": () => import("./../../../src/templates/DynamicZipPackagesSpanish.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-spanish-jsx" */),
  "component---src-templates-one-trust-privacy-policy-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-jsx" */),
  "component---src-templates-one-trust-privacy-policy-spanish-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicySpanish.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-spanish-jsx" */),
  "component---src-templates-spanish-direct-tv-jsx": () => import("./../../../src/templates/SpanishDirectTv.jsx" /* webpackChunkName: "component---src-templates-spanish-direct-tv-jsx" */),
  "component---src-templates-spanish-do-not-sell-jsx": () => import("./../../../src/templates/SpanishDoNotSell.jsx" /* webpackChunkName: "component---src-templates-spanish-do-not-sell-jsx" */),
  "component---src-templates-spanish-jsx": () => import("./../../../src/templates/Spanish.jsx" /* webpackChunkName: "component---src-templates-spanish-jsx" */),
  "component---src-templates-spanish-lp-jsx": () => import("./../../../src/templates/SpanishLp.jsx" /* webpackChunkName: "component---src-templates-spanish-lp-jsx" */),
  "component---src-templates-spanish-wireless-jsx": () => import("./../../../src/templates/SpanishWireless.jsx" /* webpackChunkName: "component---src-templates-spanish-wireless-jsx" */),
  "component---src-templates-state-jsx": () => import("./../../../src/templates/State.jsx" /* webpackChunkName: "component---src-templates-state-jsx" */),
  "component---src-templates-wireless-jsx": () => import("./../../../src/templates/Wireless.jsx" /* webpackChunkName: "component---src-templates-wireless-jsx" */)
}

